import React from 'react';
import { Card, Button, AutoAnimate, NotPhone, Phone } from 'components';
import { formatDate } from 'root/site/helpers';
import DownloadIncon from 'client/img/file/download.svg';
import './documentCard.scss';
import t from 'i18n';

export const DocumentCard = ({ record: document, isNested = false }) => {
	const [showNote, setShowNote] = React.useState(false);
	const { name, number, type, acceptBody, acceptAt, publishAt, note, filename, fileSize, archive, children, effectiveStartAt, expirationAt, ligament } =
		document || {};
	const hasChildren = !!children().length && !isNested;
	const fileExtension = filename?.split('.').pop();

	function getFileButtonRef(el) {
		if (el) {
			//! грязь, сбрасывает все ивенты
			el.outerHTML = el.outerHTML;
		}
	}

	return (
		<Card className='document-card'>
			<div className='file'>
				<Button
					href={document.downloadFile('filename')}
					getRef={getFileButtonRef}
					download
					color='grey'
					size='small'
					variant='link'
					prependIcon={<DownloadIncon />}
				>
					{fileExtension}
				</Button>
				<div className='filesize'>{fileSize}</div>
				{archive && <div className='archive'>{t('archive')}</div>}
			</div>

			<div className='info'>
				<div className='top'>
					<div className='left'>{type?.name}</div>
					<div className='publishAt'>
						{t('publishAt')} {formatDate(publishAt)}
					</div>
				</div>
				{acceptBody && <div className='organization'>{acceptBody}</div>}
				<div className='accept-number'>
					<NotPhone>
						{acceptAt && (
							<div className='acceptAt'>
								<div>{t('from')}</div>
								<div className='data'>{formatDate(acceptAt)}</div>
							</div>
						)}
						{number && (
							<div className='number'>
								<div>{t('number')}</div>
								<div className='data'>{number}</div>
							</div>
						)}
						{ligament && (
							<div className='ligament'>
								<div className='text'>{t('status')}</div>
								<div className='data'>{ligament?.name}</div>
							</div>
						)}
					</NotPhone>
					<Phone>
						{number && (
							<div className='number'>
								<div>{t('number')}</div>
								<div className='data'>{number}</div>
							</div>
						)}
					</Phone>
				</div>
				{name && <div className='name'>{name}</div>}
				{(hasChildren || note || effectiveStartAt || expirationAt) && (
					<div className='children'>
						<AutoAnimate>
							{showNote && (
								<>
									<NotPhone>
										{note && <div className='description-content'>{note}</div>}
										{effectiveStartAt && expirationAt && (
											<div className='effectife-expiration'>
												{effectiveStartAt && (
													<div className='effectiveStartAt'>
														<div className='text'>{t('document.effectiveStartAt')}</div>
														<div className='data'>{formatDate(effectiveStartAt)}</div>
													</div>
												)}
												{expirationAt && (
													<div className='expirationAt'>
														<div className='text'>{t('document.expirationAt')}</div>
														<div className='data'>{formatDate(expirationAt)}</div>
													</div>
												)}
											</div>
										)}
										{children().map((record) => (
											<DocumentCard record={record} key={record.id} isNested={true} />
										))}
									</NotPhone>
									<Phone>
										{note && <div className='description-content'>{note}</div>}
										{effectiveStartAt && (
											<div className='effectife-expiration'>
												{effectiveStartAt && (
													<div className='effectiveStartAt'>
														<div className='text'>{t('document.effectiveStartAt')}</div>
														<div className='data'>{formatDate(effectiveStartAt)}</div>
													</div>
												)}
											</div>
										)}
										{children().map((record) => (
											<DocumentCard record={record} key={record.id} isNested={true} />
										))}
									</Phone>
								</>
							)}
						</AutoAnimate>
						{!isNested && (
							<div className='toggle' onClick={() => setShowNote(!showNote)}>
								{hasChildren ? (showNote ? t('hideNoteLigament') : t('showNoteLigament')) : showNote ? t('hideNote') : t('showNote')}
							</div>
						)}
					</div>
				)}
			</div>
		</Card>
	);
};
