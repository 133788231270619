import React from 'react';
import { Card, Button } from 'components';
import DownloadIncon from 'client/img/file/download.svg';
import './attachmentCard.scss';

export const AttachmentCard = ({ record: attachment }) => {
	const { name, filename, fileSize } = attachment || {};
	const fileExtension = filename?.split('.').pop();

	function getFileButtonRef(el) {
		if (el) {
			//! грязь, сбрасывает все ивенты
			el.outerHTML = el.outerHTML;
		}
	}

	return (
		<Card className='attachment-card'>
			<div className='file'>
				<Button
					href={attachment.downloadFile('filename')}
					getRef={getFileButtonRef}
					download
					color='grey'
					size='small'
					variant='link'
					prependIcon={<DownloadIncon />}
				>
					{fileExtension}
				</Button>
				<div className='filesize'>{fileSize}</div>
			</div>

			<div className='info'>{name && <div className='name'>{name || filename}</div>}</div>
		</Card>
	);
};
