import React from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import { Link } from 'react-router-dom';
import store from 'client/store';
import DropdownMenu from './DropdownMenu';
import t from 'i18n';
import IMAGES_MAP from 'constants/MenuPortalFooterImages';

@observer
export class Menu extends React.Component {
	generateLinkPath = (record) => {
		const { module } = record;

		if (!module) {
			return '/';
		}

		const pathFields = ['subCategoryCode', 'subjectCode'];

		for (let field of pathFields) {
			if (record[field]) {
				return `${module}/${field.replace('Code', '')}/${record[field]}`;
			}
		}

		return module;
	};

	render() {
		const isActive = (path) => (path === store.route.path ? ' active' : '');
		const getMenuElement = (record) => {
			const { link, module, name, subCategoryName, subjectName } = record;
			const linkToChoice = this.generateLinkPath(record);
			const displayName = subCategoryName || subjectName || t(`sites.${module}`);
			return !record.hasOwnProperty('module') ? (
				<Link to={{ pathname: link }} target='_blank'>
					<div className='link-content'>
						<span>{name}</span>
					</div>
				</Link>
			) : (
				<Link to={`/${linkToChoice}`}>
					<div className='link-content'>
						{IMAGES_MAP[module]} {displayName}
					</div>
				</Link>
			);
		};

		const items = [];
		store.ui.menuMain.forEach((menu) => {
			const { module, children } = menu;
			const activePath = this.generateLinkPath(menu);
			items.push(
				<div className={this.props.className + (module ? isActive(`/${activePath}`) : '')} key={items.length}>
					{getMenuElement(menu)}
					{!!children.length && <DropdownMenu items={children.map((x) => getMenuElement(x))} />}
				</div>
			);
		});
		return <div className='links'>{items}</div>;
	}
}
