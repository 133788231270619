import React from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import { observable } from 'mobx';
import { DocumentCard, AttachmentCard } from 'components';
import './style.scss';

@observer
export default class TextWithDocuments extends React.Component {
	@observable documents = [];
	@observable attachments = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		if (this.props.content) {
			const { documentIds, attachmentIds } = this.extractDocumentAttachmentIds(this.props.content);
			this.fetchRecords(documentIds, attachmentIds);
		}
	};

	fetchRecords = async (documentIds, attachmentIds) => {
		if ((!documentIds || documentIds.length === 0) && (!attachmentIds || attachmentIds.length === 0)) {
			this.isLoading = false;
			return;
		}

		try {
			if (documentIds && documentIds.length > 0) {
				const documents = await store.model.Document.find({
					where: {
						id: { inq: documentIds },
					},
					include: ['organization', 'type', 'children'],
				});
				this.documents = documents;
			}

			if (attachmentIds && attachmentIds.length > 0) {
				const attachments = await store.model.Attachment.find({
					where: {
						id: { inq: attachmentIds },
					},
				});
				this.attachments = attachments;
			}
		} catch (error) {
			console.error(`Failed to fetch`, error);
		}

		this.isLoading = false;
	};

	replaceDocumentIdsWithComponent = (htmlContent) => {
		const regex = /\[(document|localDocument) id: (\d+)\]/g;
		const parts = [];
		let match;

		// Удаляем все, что не соответствует [document id: ...] или [localDocument id: ...]
		while ((match = regex.exec(htmlContent)) !== null) {
			const type = match[1];
			const id = Number(match[2]);

			let document = null;
			let attachment = null;

			if (type === 'document') {
				document = this.documents.find((doc) => doc.id === id);
			} else if (type === 'localDocument') {
				attachment = this.attachments.find((attachment) => attachment.id === id);
			}

			if (document) {
				parts.push(
					<div className='documents-page' key={`document-${type}-${id}`}>
						<DocumentCard record={document} />
					</div>
				);
			}

			if (attachment) {
				parts.push(
					<div className='documents-page' key={`document-${type}-${id}`}>
						<AttachmentCard record={attachment} />
					</div>
				);
			}
		}

		return parts;
	};

	extractDocumentAttachmentIds = (text) => {
		const documentRegex = /\[document id: (\d+)\]/g;
		const localDocumentRegex = /\[localDocument id: (\d+)\]/g;

		const documentIds = [];
		const attachmentIds = [];

		let match;

		// Поиск [document id: ]
		while ((match = documentRegex.exec(text)) !== null) {
			const id = Number(match[1]);
			if (!documentIds.includes(id)) {
				documentIds.push(id);
			}
		}

		// Поиск [localDocument id: ]
		while ((match = localDocumentRegex.exec(text)) !== null) {
			const id = Number(match[1]);
			if (!attachmentIds.includes(id)) {
				attachmentIds.push(id);
			}
		}

		return { documentIds, attachmentIds };
	};

	render() {
		if (this.isLoading) return null;

		const { content, title } = this.props;

		if (!content) return null;

		return (
			<div className='wrapper-text-with-documents'>
				{title && <div className='text-block-title p4'>{title}</div>}
				<div className='management-person-page-content-text p1'>{this.replaceDocumentIdsWithComponent(content)}</div>
			</div>
		);
	}
}
